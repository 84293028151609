body {
  margin: 0 ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
 align-items: center;
 
 
 background: linear-gradient(45deg, #000000, #007fff, #f7fafb);
 background-size: 600% 600%;
 
 -webkit-animation: AnimationName 15s ease infinite;
 -moz-animation: AnimationName 15s ease infinite;
 animation: AnimationName 15s ease infinite;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 51%}
  100%{background-position:0% 50%}
}