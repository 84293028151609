@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Oswald:wght@200;300;400&family=Poppins:wght@400;500;600&family=Roboto:wght@300;400;500&display=swap');
*{
font-family: 'Lato', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Roboto', sans-serif;
}
.table-container{
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0px 30px;
    padding: 5px;
    padding-bottom: 10px;
    opacity: 0.9;
    box-sizing: border-box;
    
}
.value{
    justify-content: center;
    align-items: center;
    text-align: center;
}

input{
    margin-right: 0px;
    margin-left: 0px;
    border-radius: 5px !important;
    width: 25px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
th{
   margin: 0px;
   padding:0px;
   font-size: small;
}
tr{
    padding:10px
}
.form-control{
    padding: 0.275rem 0.55rem !important;
}


.table>:not(caption)>*>* {
    padding: 0.5rem 0.7rem;
  
}

.mobile{
    display:none;
}
@media (max-width: 600px) {
    .mobile{
        display:flex;
        text-align: center;
        justify-content: center;
        color:aliceblue;
        font-size: 2rem;
    }
    .allin-container{
        display:block;
        margin: 10px;
        justify-content:center;
        padding: 10px;
    }
    /* buraya bak */
    .table-container{
        display:block;
        padding:0
    }
 
}
 .table-result{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
/* burayı büyüğüdüğünde flex olacak şekilde media query yaz */
.allin-container{
    display:block
}
@media (min-width: 900px) {
    .allin-container{
    display:flex ;
    align-items: start;
    margin: 0 !important;
    padding:0 !important;
}
}